<template>

    <form class="needs-validation tab-pane fade" ref="form" :v-model="valid" lazy-validation autocomplete="off" novalidate id="signup-tab">
    <div class="form-group">
                <label for="su-name">Full name</label>
                <input class="form-control" v-model="name"
          :rules="nameRules"
          :counter="255"
          label="Name"
          type="text" id="su-name" placeholder="John Doe" required>
                <div class="invalid-feedback">Please fill in your name.</div>
              </div>
              <div class="form-group">
                <label for="su-email">Email address</label>
                <input class="form-control" v-model="email" :rules="emailRules" label="E-mail" type="email" id="su-email" placeholder="johndoe@example.com" required>
                <div class="invalid-feedback">Please provide a valid email address.</div>
              </div>
              <div class="form-group">
                <label for="su-email">Phone</label>
                <input class="form-control" v-model="phone" label="Phone" type="text" id="su-phone" placeholder="0812xxxxxxx" required>
                <div class="invalid-feedback">Please provide a valid phone number.</div>
              </div>
              <div class="form-group">
                <label for="su-password">Password</label>
                <div class="password-toggle">
                  <input class="form-control"  v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules"
                    :type="showPassword ? 'text' : 'password'"
                    label="Password"
                    hint="At least 6 characters"
                    counter
                    @click:append="showPassword = !showPassword" id="su-password" required>
                  <!-- <label class="password-toggle-btn">
                    <input class="custom-control-input" type="checkbox"><i class="czi-eye password-toggle-indicator"></i><span class="sr-only">Show password</span>
                  </label> -->
                </div>
              </div>
               <div class="form-group">
                <label for="su-email">Shipping Address</label>
                <input class="form-control mb-2" v-model="address_line1" type="text" placeholder="Address Line1*" required>
                <input class="form-control mb-2" v-model="address_line2" type="text" placeholder="Address Line2" required>
                <input class="form-control" v-model="address_line3" type="text" placeholder="Address Line3" required>
                <div class="invalid-feedback">Please provide a valid address.</div>
              </div>
               <div class="form-group">
                 <div class="row">
                   <div class="col-6">
                      <label for="checkout-province">Province</label>
                <select name="province" class="form-control custom-select" id="checkout-province" aria-readonly  v-model='province_id' @change='citiesByProvince()' >
                  <option v-if="user.province_id<1" value="" selected>Choose province</option>
                  <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.province }}</option>
                </select>
                     
                   </div>
                   <div class="col-6">
                     <label for="su-email">City</label>
                     <select class="form-control custom-select" id="checkout-city" v-model='city_id'>
                      <option value="">Choose city</option>
                      <option class="py-1" v-for="city in citiesByProvince" :key="city.id" :value="city.id">{{ city.city }}</option>
                    </select>
                   </div>
                 </div>
              </div>
              <!-- <div class="form-group">
                <label for="su-password-confirm">Confirm password</label>
                <div class="password-toggle">
                  <input class="form-control" type="password" id="su-password-confirm" required>
                  <label class="password-toggle-btn">
                    <input class="custom-control-input" type="checkbox"><i class="czi-eye password-toggle-indicator"></i><span class="sr-only">Show password</span>
                  </label>
                </div>
              </div> -->
              <button class="btn btn-primary btn-block btn-shadow" data-dismiss="modal" :disabled="!valid"
          @click="submit">Sign up</button>
          </form>
           
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'Register',
    data () {
        return {
            valid: true,
            name: '',
            nameRules: [
            v => !!v || 'Name is required',
            v => (v && v.length <= 255) || 'Name must be less than 255 characters'
            ],
            email: '',
            emailRules: [
            v => !!v || 'E-mail is required',
            v => /([a-zA-Z0-9_]{1,})(@)([a-zA-Z0-9_]{2,}).([a-zA-Z0-9_]{2,})+/.test(v) || 'E-mail must be valid'
            ],
            showPassword: false,
            password: '',
            passwordRules: [
            v => !!v || 'Password required.',
            v => (v && v.length >= 6) || 'Min 6 characters',
            ],
            phone: '',
            checkbox: false,
            address_line1: '',
            address_line2: '',
            address_line3: '',
            province_id: 0,
            city_id: 0,


        }
    },
    computed: {
      ...mapGetters({
        provinces     : 'region/provinces',
        cities        : 'region/cities',
        user          : 'auth/user',
      }),
      citiesByProvince(){
        let province_id = this.user.province_id
        return this.cities.filter((city) => {           
            if (city.province_id==province_id) return city
        })
      },

      
    },
    methods: {
        ...mapActions({
          setAlert  : 'alert/set',
          setAuth   : 'auth/set',
          setProvinces      : 'region/setProvinces',
          setCities         : 'region/setCities',
        }),
        submit () {
            // if (this.$refs.form.validate()) {
                let formData = new FormData()
                formData.set('name', this.name)
                formData.set('email', this.email)
                formData.set('phone', this.phone)
                formData.set('password', this.password)
                formData.set('address_line1', this.address_line1)
                formData.set('address_line2', this.address_line2)
                formData.set('address_line3', this.address_line3)
                formData.set('province_id', this.province_id)
                formData.set('city_id', this.city_id)
                this.axios.post('/register', formData)
                    .then((response) => {
                        let { data } = response.data
                        this.setAuth(data)
                        this.setAlert({
                            status : true,
                            color  : 'success',
                            text  : 'Register success',
                        })
                        this.close()
                    })
                .catch((error) => {
                    let { data } = error.response
                    this.setAlert({
                        status : true,
                        color  : 'danger',
                        text  : data.message,
                    })
                })
            // }
        },
        close() {
            this.$emit('closed', false)
            this.$router.push('/account/profile')
        },
        clear () {
            this.$refs.form.reset()
        }
    },
    created(){


        if(this.provinces && this.provinces.length==0){
            this.axios.get('/provinces')
            .then((response) => {
                let { data } = response.data
                this.setProvinces(data)
                
            })

            this.axios.get('/cities')
            .then((response) => {
                let { data } = response.data
                this.setCities(data)
            })
        }
    }
    
}
</script>